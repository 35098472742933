<template>
  <main class="plans all-plans">
    <b-container>
      <b-row>
        <b-col cols="12" md="8">
          <h2 class="plans__title">{{ $t("heading.bundls") }}</h2>
          <p class="plans__text">
             {{ $t("heading.bundles_description") }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="mx-auto"
          :class="{ 'p-0': plans.length > 3 }"
          cols="12"
          :sm="plans.length > 3 ? 2 : 4"
          v-for="(plan, index) in plans"
          :key="index"
        >
          <Plan :showFeatures="true" :plan="plan" :plans="plans" />
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>
<script>
import { IndexData } from "@/helpers/apiMethods";
export default {
  name: "AllPlans",
  components: {
    Plan: () => import("@/components/Plans/components/Plan.vue")
  },
  data() {
    return {
      plans: []
    };
  },
  created() {
    this.hadnleGetPlans();
  },
  methods: {
    async hadnleGetPlans() {
      const res = await IndexData({
        reqName: "plans",
        query: this.$route.query
      });
      const { plans } = res.data;
      this.plans = plans.map((el, index) => {
        return {
          ...el,
          last_plan: plans.length - 1 == index ? true : false
        };
      });
    }
  }
};
</script>
